<template>
  <v-form>
    <v-card max-width="800px" class="mt-4 mx-auto">
      <v-card-title v-text="$t('Customer')" />
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                :error-messages="emailErrors"
                :label="$t('email')"
                @blur="$v.item.email.$touch()"
                @input="$v.item.email.$touch()"
                required
                v-model="item.email"
              />
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                v-if="loginEnabledForCustomer"
                :error-messages="plainPasswordErrors"
                :label="$t('password')"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                @blur="$v.item.plainPassword.$touch()"
                @input="$v.item.plainPassword.$touch()"
                required
                v-model="item.plainPassword"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="6">
              <v-select
                :items="salutationItems"
                :error-messages="salutationErrors"
                :label="$t('salutation')"
                @blur="$v.item.profile.salutation.$touch()"
                @input="$v.item.profile.salutation.$touch()"
                v-model="item.profile.salutation"
              />
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                :error-messages="titleErrors"
                :label="$t('title')"
                @blur="$v.item.profile.title.$touch()"
                @input="$v.item.profile.title.$touch()"
                v-model="item.profile.title"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                :error-messages="firstNameErrors"
                :label="$t('firstName')"
                @blur="$v.item.profile.firstName.$touch()"
                @input="$v.item.profile.firstName.$touch()"
                required
                v-model="item.profile.firstName"
              />
            </v-col>

            <v-col cols="12" md="6" sm="6">
              <v-text-field
                :error-messages="lastNameErrors"
                :label="$t('lastName')"
                @blur="$v.item.profile.lastName.$touch()"
                @input="$v.item.profile.lastName.$touch()"
                v-model="item.profile.lastName"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                :error-messages="companyErrors"
                :label="$t('company')"
                @blur="$v.item.profile.company.$touch()"
                @input="$v.item.profile.company.$touch()"
                v-model="item.profile.company"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="8" md="6">
              <v-file-input
                :rules="imageRules"
                :label="$t('profileImage')"
                :placeholder="$t('profileImagePlaceholder')"
                v-model="uploadImage"
                accept="image/png, image/jpeg"
                prepend-icon="mdi-camera"
                @change="onFileUpload"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row v-if="loginEnabledForCustomer && !hasDefaultGroup">
            <v-col cols="12" md="6" sm="6">
              <v-combobox
                :error-messages="groupsErrors"
                :items="groupsSelectItems"
                :label="$t('groups')"
                :no-data-text="$t('No results')"
                chips
                item-text="name"
                item-value="@id"
                multiple
                v-model="item.groups"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="6">
              <v-checkbox
                v-if="loginEnabledForCustomer"
                :error-messages="enabledErrors"
                :label="$t('enabled')"
                @blur="$v.item.enabled.$touch()"
                @input="$v.item.enabled.$touch()"
                v-model="item.enabled"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import has from "lodash/has";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import TenantMixin from "@/mixins/TenantMixin";
import DocumentMixin from "@/mixins/DocumentMixin";

export default {
  name: "CustomerForm",
  mixins: [validationMixin, TenantMixin, DocumentMixin],
  props: {
    values: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => {},
    },

    initialValues: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.groupsGetSelectItems();
  },
  data() {
    return {
      showPassword: false,
      groups: null,
      email: null,
      profile: {
        firstName: null,
        lastName: null,
      },
      plainPassword: null,
      enabled: null,
      locked: null,
      salutationItems: ["Frau", "Herr", "Divers"],
      imageRules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Avatar size should be less than 2 MB!",
      ],
      uploadImage: null,
    };
  },
  computed: {
    ...mapFields("frontendGroup", {
      groupsSelectItems: "selectItems",
      groupsById: "byId",
    }),
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },
    hasDefaultGroup() {
      return !!this.getAccountSettings().defaultCustomerGroup;
    },
    loginEnabledForCustomer() {
      return this.getAccountSettings().customerLoginEnabled;
    },
    // region errorFields
    groupsErrors() {
      const errors = [];

      if (!this.$v.item.groups.$dirty) {
        return errors;
      }

      has(this.violations, "groups") && errors.push(this.violations.groups);

      return errors;
    },
    emailErrors() {
      const errors = [];

      if (!this.$v.item.email.$dirty) {
        return errors;
      }

      has(this.violations, "email") && errors.push(this.violations.email);

      !this.$v.item.email.required && errors.push(this.$t("Field is required"));

      return errors;
    },
    firstNameErrors() {
      const errors = [];

      if (!this.$v.item.profile.firstName.$dirty) {
        return errors;
      }

      has(this.violations, "profile.firstName") &&
        errors.push(this.violations.profile.firstName);

      !this.$v.item.profile.firstName.required &&
        errors.push(this.$t("Field is required"));

      return errors;
    },
    lastNameErrors() {
      const errors = [];

      if (!this.$v.item.profile.lastName.$dirty) {
        return errors;
      }

      has(this.violations, "profile.lastName") &&
        errors.push(this.violations.profile.lastName);

      !this.$v.item.profile.lastName.required &&
        errors.push(this.$t("Field is required"));

      return errors;
    },
    plainPasswordErrors() {
      const errors = [];

      if (!this.$v.item.plainPassword.$dirty) {
        return errors;
      }

      has(this.violations, "plainPassword") &&
        errors.push(this.violations.plainPassword);
      !this.$v.item.plainPassword.required &&
        errors.push(this.$t("Field is required"));
      !this.$v.item.plainPassword.minLength && errors.push(this.$t("MinChar6"));

      return errors;
    },
    enabledErrors() {
      const errors = [];

      if (!this.$v.item.enabled.$dirty) {
        return errors;
      }

      has(this.violations, "enabled") && errors.push(this.violations.enabled);

      return errors;
    },
    lockedErrors() {
      const errors = [];

      if (!this.$v.item.locked.$dirty) {
        return errors;
      }

      has(this.violations, "locked") && errors.push(this.violations.locked);

      return errors;
    },
    salutationErrors() {
      const errors = [];

      if (!this.$v.item.profile.salutation.$dirty) {
        return errors;
      }
      has(this.violations, "salutation") &&
        errors.push(this.violations.salutation);

      return errors;
    },
    titleErrors() {
      const errors = [];

      if (!this.$v.item.profile.title.$dirty) {
        return errors;
      }
      has(this.violations, "title") && errors.push(this.violations.title);

      return errors;
    },
    companyErrors() {
      const errors = [];

      if (!this.$v.item.profile.company.$dirty) {
        return errors;
      }
      has(this.violations, "company") && errors.push(this.violations.company);

      return errors;
    },
    violations() {
      return this.errors || {};
    },
    // endregion
  },
  methods: {
    ...mapActions({
      groupsGetSelectItems: "frontendGroup/fetchSelectItems",
      retrieve: "frontendGroup/load",
      frontendGroupById: "frontendGroup/byId",
    }),
    setDefaultGroup() {
      if (this.hasDefaultGroup) {
        this.item["groups"] = [];
        this.item.groups.push(this.getAccountSettings().defaultCustomerGroup);
      }
    },
    onFileUpload(file) {
        this.uploadDocument(file);
    }
  },
  validations: {
    item: {
      groups: {},
      profile: {
        firstName: {
          required,
        },
        lastName: {
          required,
        },
        salutation: {},
        title: {},
        company: {},
      },
      email: {
        required,
      },
      plainPassword: {
        required,
        minLength: minLength(6),
      },
      enabled: {},
      locked: {},
    },
  },
  created() {
    this.setDefaultGroup();
    if (this.loginEnabledForCustomer === false) {
      this.item.enabled = false;
      this.item.plainPassword = Math.random()
        .toString(36)
        .slice(2);
    }
  },
  watch: {
     uploadedDocument() {
      if (!this.uploadedDocument) {
        return;
      }
      this.item.profile.image = this.uploadedDocument['@id'];
    }
  }
};
</script>
