<template>
  <div>
    <Toolbar :handle-reset="resetForm" :handle-submit="onSendForm"></Toolbar>
    <CustomerForm :errors="violations" :values="item" ref="createForm"/>
    <Loading :visible="isLoading"/>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import {createHelpers} from 'vuex-map-fields';
import CustomerForm from '../../components/customer/CreateForm';
import Loading from '../../components/Loading';
import Toolbar from '../../components/Toolbar';
import CreateMixin from '../../mixins/CreateMixin';

const servicePrefix = 'Customer';

const {mapFields} = createHelpers({
  getterType: 'customer/getField',
  mutationType: 'customer/updateField'
});

export default {
  name: 'CustomerCreate',
  servicePrefix,
  mixins: [CreateMixin],
  components: {
    Loading,
    Toolbar,
    CustomerForm
  },
  data() {
    return {
      item: {
        profile: {},
        groups: []
      }
    };
  },
  computed: {
    ...mapFields(['error', 'isLoading', 'created', 'violations'])
  },
  methods: {
    ...mapActions('customer', ['create', 'reset']),
    getItemSubs() {
      return {
        profile: {}
      }
    }
  }
};
</script>
